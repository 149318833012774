import React from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import logo from "../images/spkl-logo-color.png";

const Footer = props => (
  <div className="footer-strip">
    <div className="container">
      <div className="footer-logo">
        <img alt="Figurit Homepage" src={logo} />
      </div>
    </div>
    <div className="container">
      <div className="footer">
        <div className="row">
          <div className="col-md-2">
            <ul>
              <li className="addtp">Connect with us</li>
              {/* <li>044 345 678903</li> */}
              <li>info@spkl.com</li>
            </ul>
          </div>
          <div className="col-12 col-md-2">
            <ul>
              <li>
                <a className="nav-link" href="/">
                  Home
                </a>
              </li>
              {/* <li><a className="nav-link" href="/media">Media</a></li> */}
              <li>
                <a className="nav-link" href="/contact">
                  Contact Us
                </a>
              </li>
              {/* <li>Term of Service</li> */}
            </ul>
          </div>
          <div className="col-12 col-md-2">
            <ul>
              <li>
                <a className="nav-link" href="/breed">
                  Our Breed
                </a>
              </li>
              <li>
                <a className="nav-link" href="/difference">
                  Our Difference
                </a>
              </li>
              <li>
                <a className="nav-link" href="/history">
                  Our History
                </a>
              </li>
              <li>
                <a className="nav-link" href="/beliefs">
                  Beliefs
                </a>
              </li>
            </ul>
          </div>

          <div className="col-12 col-md-2">
            <ul>
              <li>
                <a className="nav-link" href="/producers">
                  Producers
                </a>
              </li>
              {/* <li>
                <a className="nav-link" href="/partners">
                  Partners
                </a>
              </li> */}
              <li>
                <a className="nav-link" href="/people">
                  People
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-4 other-detail">
            <ul className="company-info">
              <li>
                &copy; 2019 Speckle brand and SPKL logo <br />
                are registered property of Speckle Park Group, Australia
              </li>
              <li className="mt-2">
                Digital presence by{" "}
                <a href="http://itelasoft.com.au">iTelaSoft</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => <Footer data={data} />}
  />
);
